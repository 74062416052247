@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply w-full min-w-[320px] overflow-x-hidden bg-pt-grey font-sans text-base font-normal leading-[150%] text-pt-dark-indigo;
  overflow-y: auto;
  animation-delay: 0ms;
}

* {
  @apply outline-none;
  -webkit-tap-highlight-color: transparent;
}

/*CUSTOM SCROLLBAR*/
::-webkit-scrollbar {
  @apply h-1 w-1;
}

/* Track */
::-webkit-scrollbar-track {
  @apply bg-transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  @apply border border-transparent bg-pt-light-indigo;
}

::selection {
  @apply bg-pt-dark-indigo text-pt-light-indigo;
}

.next-image {
  position: relative !important;
  /* height: min-content !important; */
}

/* Mesh overlay for blog posts */
.mesh-overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAQAAAAD+Fb1AAAAEklEQVR42mNg+M8AAhASDUClAEXeA/1LlYqzAAAAAElFTkSuQmCC);
  opacity: 0.3;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=75)';
  filter: alpha(opacity=75);
  pointer-events: none;
}

/* Hide up and down arrows on quantity inputs */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
